import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  name: 'GtrTrackReportsView',
  computed: {
    ...mapState('report', ['reports', 'reportSnapshotResultDownload']),
    ...mapState('module', ['activatedEventModules']),
    ...mapState('sessions', ['all_activities'])
  }
})
export default class GtrTrackReportsView extends Vue {
  activatedEventModules!: Record<string, any>

  data () {
    return {
      allActivities: [],
      table: {
        loading: false,
        search: '',
        headers: [
          { text: 'Name', align: 'start', sortable: true, filterable: true, value: 'name' },
          { text: '', align: 'center', sortable: false, filterable: false, value: 'actions', width: '90px' }
        ],
        items: []
      },
      reportToDelete: null
    }
  }

  async mounted () {
    await this.fetchReports()
  }

  @Watch('reports', { immediate: true })
  onReportsChange (payload: any) {
    if (payload.data) {
      this.$data.table.items = payload.data
    }
  }

  @Watch('reportSnapshotResultDownload', { immediate: true })
  onReportSnapshotResultDownloadChange (payload: any) {
    if (payload.url) {
      location.href = payload.url
    }
  }

  get activeReports () {
    const reports: any[] = []

    this.$data.table.items.forEach(report => {
      if (report.module && report.module === 'SESSION_TRACKING' && this.activatedEventModules[report.module].enabled) {
        reports.push(report)
      }
    })

    return reports
  }

  async handleExportReport (report: any) {
    try {
      this.$store.dispatch('common/showLoader', { value: true })
      const payload: any = {
        event_uuid: this.$route.params.event_uuid,
        report_uuid: report.uuid,
        export_report: true
      }
      await this.$store.dispatch('report/getReportSnapshot', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$store.dispatch('common/hideLoader')
    }
  }

  async onDeleteReportAction (payload: any) {
    if (payload.confirm) {
      try {
        this.$data.table.loading = true
        const payload: any = {
          event_uuid: this.$route.params.event_uuid,
          report_uuid: this.$data.reportToDelete.uuid
        }
        await this.$store.dispatch('report/deleteReport', payload)
        Container.get(Notification).success('Report successfully deleted.')
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.table.loading = false
      }
    }
    this.$data.reportToDelete = null
  }

  handleViewReport (report: any) {
    this.$router.push({
      name: 'level-two.modules.track.reports.view',
      params: Object.assign(this.$route.params, {
        report_uuid: report.uuid
      })
    })
  }

  handleEditReport (report: any) {
    this.$router.push({
      name: 'level-two.modules.track.reports.edit',
      params: Object.assign(this.$route.params, {
        report_uuid: report.uuid
      })
    })
  }

  handleDeleteReport (report: any) {
    this.$data.reportToDelete = report
  }

  private async fetchReports () {
    if (this.$route.params.event_uuid) {
      this.$data.table.loading = true
      await this.$store.dispatch('report/getReports', this.$route.params.event_uuid)
      this.$data.table.loading = false
    }
  }

  private async fetchAllActivities () {
    if (this.$route.params.event_uuid) {
      this.$data.table.loading = true
      await this.$store.dispatch('sessions/fetchAllActivities', this.$route.params.event_uuid)
      this.$data.table.loading = false
    }
  }
}
